export const LOGIN = "@auth/LOGIN"
export const LOGOUT = "@auth/LOGOUT"
export const RELOAD_USER = "@auth/RELOAD_USER"
export const UPDATE_USER = "@auth/UPDATE_USER"
export const REGISTER = "@auth/REGISTER"
export const FETCH_TRACKS = "@tracks/FETCH_TRACKS"
export const FETCH_BLACKLIST = "@tracks/FETCH_BLACKLIST"
export const FETCH_ARTISTS = "@artists/FETCH_ARTISTS"
export const FETCH_PLAYLISTS = "@playlists/FETCH_PLAYLISTS"
export const FETCH_PLAYLIST = "@playlists/FETCH_PLAYLIST"
export const UPDATE_PLAYLISTS_HOT_LIST = "@playlists/UPDATE_PLAYLISTS_HOT_LIST"
export const FETCH_COMPANIES = "@companies/FETCH_COMPANIES"
export const FETCH_COMPANY_BREAKDOWNS = "@companies/FETCH_COMPANY_BREAKDOWNS"

export const FETCH_CONFIRMED_OFFERS = "@companies/FETCH_CONFIRMED_OFFERS"
