import { useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

// project-imports
import useAuth from "hooks/useAuth"

// types
import { GuardProps } from "types/auth"
import { UserRoles } from "pages/offers/resources/constants"

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { artistCode } = useParams()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/${artistCode}/`, {
        state: {
          from: location.pathname
        },
        replace: true
      })
    } else {
      if (!user?.role_ids?.includes(UserRoles.OFFERS_ACCESS)) {
        navigate(`/${artistCode}/`, {
          state: {
            from: ""
          },
          replace: true
        })
      }
    }
  }, [isLoggedIn, navigate, location])

  return children
}

export default AuthGuard
