import { REGISTER, LOGIN, LOGOUT, RELOAD_USER, UPDATE_USER } from "./actions"
import { AuthProps, AuthActionProps } from "types/auth"

export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  roleIds: [],
  user: null
}

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload!
      return {
        ...state,
        user
      }
    }
    case LOGIN: {
      const { user } = action.payload!
      return {
        ...state,
        isLoggedIn: true,
        roleIds: user?.role_ids,
        isInitialized: true,
        user
      }
    }
    case UPDATE_USER: {
      const { user } = action.payload!
      return {
        ...state,
        user
      }
    }
    case RELOAD_USER: {
      return {
        ...state
      }
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        roleIds: [],
        isLoggedIn: false,
        user: null
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default auth
