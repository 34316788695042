import { useEffect } from "react"

import { Outlet } from "react-router-dom"

// material-ui
import { styled } from "@mui/material/styles"
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress"
import useConfig from "../../../hooks/useConfig"
import Header from "./Header"

// ==============================|| LOADER - WRAPPER ||============================== //

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2)
  }
}))

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
)

// ==============================|| MINIMAL LAYOUT ||============================== //

const OffersMainLayout = ({ layout = "blank", backgroundColor }: { layout?: string; backgroundColor: string }) => {
  const { onChangePresetColor } = useConfig()

  useEffect(() => {
    onChangePresetColor("theme1")
  }, [])

  const renderOther = () => {
    return (
      <div style={{ height: "100vh", backgroundColor }} id={"main-layout"}>
        <div style={{ height: "10%" }}>
          <Header layout={layout} backgroundColor={backgroundColor} />
        </div>
        <div style={{ height: "90%" }}>
          <Outlet />
        </div>
      </div>
    )
  }

  const renderPartial = () => {
    return (
      <div
        style={{ position: "relative", height: "100vh", backgroundColor: "#F0F0F0", padding: 10, overflowX: "hidden" }}
        id={"main-layout"}
      >
        <div style={{ backgroundColor, height: "100%", borderRadius: 10, boxShadow: "0px 2px 2px 0px lightgray" }}>
          <div className="absolute" style={{ zIndex: 999, width: "100%", paddingRight: 22 }}>
            <Header layout={layout} backgroundColor={backgroundColor} />
          </div>
          <div style={{ height: "10%" }} />
          <div style={{ height: "90%" }}>
            <Outlet />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "20px",
            height: "20px",
            backgroundColor: "#F0F0F0",
            zIndex: 10,
            overflow: "hidden"
            //boxShadow: "0px 2px 2px 0px lightgray"
          }}
        >
          <div
            style={{
              position: "absolute",
              top: -10,
              left: -10,
              width: "20px",
              height: "20px",
              zIndex: 10,
              borderRadius: "10px",
              backgroundColor: "#FFF"
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "20px",
            height: "20px",
            backgroundColor: "#F0F0F0",
            zIndex: 10,
            overflow: "hidden"
            //boxShadow: "0px 2px 2px 0px lightgray"
          }}
        >
          <div
            style={{
              position: "absolute",
              top: -10,
              right: -10,
              width: "20px",
              height: "20px",
              zIndex: 10,
              borderRadius: "10px",
              backgroundColor: "#FFF"
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: "10px",
            width: "100%",
            backgroundColor: "#F0F0F0",
            zIndex: 10,
            boxShadow: "0px 2px 2px 0px lightgray"
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: -1,
            width: "11px",
            height: "100%",
            backgroundColor: "#F0F0F0",
            zIndex: 10
            //boxShadow: "0px 2px 2px 0px lightgray"
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: -1,
            width: "11px",
            height: "100%",
            backgroundColor: "#F0F0F0",
            zIndex: 10
            //boxShadow: "0px 2px 2px 0px lightgray"
          }}
        />
      </div>
    )
  }

  return layout === "partial" || layout === "landing-text" ? renderPartial() : renderOther()
}

export default OffersMainLayout
