import { useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

// project-imports
import useAuth from "hooks/useAuth"

// types
import { GuardProps } from "types/auth"

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { artistCode } = useParams()

  useEffect(() => {
    if (!location.pathname.includes("login")) {
      return
    }

    if (isLoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : `/${artistCode}`, {
        state: {
          from: ""
        },
        replace: true
      })
    }
  }, [isLoggedIn, navigate, location])

  return children
}

export default GuestGuard
