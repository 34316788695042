import axios from "axios"
import { setSession } from "../contexts/JWTContext"

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config

    if (error.response?.status === 401 && error.config.url !== "/login/" && error.config.url !== "/refresh/" && !originalRequest._retry) {
      originalRequest._retry = true
      return axiosServices.post("/refresh/", {}, { withCredentials: true }).then((response) => {
        if (response?.data?.token) {
          const serviceToken = response.data.token
          setSession(serviceToken)
          originalRequest.headers.Authorization = `Bearer ${serviceToken}`
          return axiosServices(originalRequest)
        } else {
          setSession(null)
          window.location.pathname = "/"
          return Promise.reject({ response: error.response })
        }
      }).catch((err) => {
        setSession(null)
        window.location.pathname = "/"
        return Promise.reject({ response: error.response })
      })
    }
    return Promise.reject({ response: error.response })
  }
)

export default axiosServices
