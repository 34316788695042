import { lazy } from "react"
import OffersMainLayout from "layout/offers/MainLayout"
import OffersCommonLayout from "layout/offers/CommonLayout"
import Loadable from "components/Loadable"
import AuthGuard from "utils/route-guard/AuthGuard"

const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/error/404")))
const MaintenanceError500 = Loadable(lazy(() => import("pages/maintenance/error/500")))
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction/under-construction"))
)
const ArtistOverview = Loadable(lazy(() => import("pages/offers/overview/ArtistOverview")))
const Account = Loadable(lazy(() => import("pages/offers/account/Account")))
const ThankYou = Loadable(lazy(() => import("pages/offers/overview/ThankYou")))

const OffersRoutes = {
  path: "/:artistCode",
  children: [
    {
      path: "/:artistCode",
      element: (
        <AuthGuard>
          <OffersMainLayout backgroundColor={"#FFFFFF"} layout={"partial"} />
        </AuthGuard>
      ),
      children: [
        {
          path: "artist/overview",
          element: <ArtistOverview />
        },
        {
          path: "account/profile",
          element: <Account selected="profile" />
        },
        {
          path: "account/contracts",
          element: <Account selected="contracts" />
        }
      ]
    },
    {
      path: "/:artistCode",
      element: (
        <AuthGuard>
          <OffersMainLayout backgroundColor={"#FFFFFF"} layout="landing-text" />
        </AuthGuard>
      ),
      children: [
        {
          path: "thanks",
          element: <ThankYou isLanding={false} />
        }
      ]
    },
    {
      path: "/:artistCode/maintenance",
      element: <OffersCommonLayout backgroundColor={"#FFFFFF"} />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />
        },
        {
          path: "500",
          element: <MaintenanceError500 />
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />
        }
      ]
    }
  ]
}

export default OffersRoutes
