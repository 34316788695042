import { useRoutes } from "react-router-dom"

// project-imports
import OffersRoutes from "./OffersRoutes"
import OffersLoginRoutes from "./OffersLoginRoutes"

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([OffersLoginRoutes, OffersRoutes])
}
