import { createContext, ReactElement, useReducer } from "react"
import axios from "utils/axios"
import { FETCH_CONFIRMED_OFFERS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { ReportsContextType, ConfirmedOffer } from "types/reports"
import reportsReducer, { initialReportsState } from "store/reducers/reports"

const ReportsContext = createContext<ReportsContextType | null>(null)

export const ReportsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reportsReducer, initialReportsState)

  const fetchConfirmedOfferList = async (orderBy: string, limit: number, offset: number) => {
    const response = await axios(`/reports/confirmed-offers/?limit=${limit}&offset=${offset}&order_by=${orderBy}&scouting=1`, {
      method: "get"
    })

    dispatch({
      type: FETCH_CONFIRMED_OFFERS,
      payload: {
        ...state,
        paginatedConfirmedOfferList: mapPaginatedDataToCamelCase<PaginatedData<ConfirmedOffer>>(response.data)
      }
    })
  }

  return (
    <ReportsContext.Provider value={{ ...state, fetchConfirmedOfferList }}>
      {children}
    </ReportsContext.Provider>
  )
}

export default ReportsContext
